import React from "react";
import { Layout } from "components";

import "./legal-returns-app.scss";

const LegalApp = () => {
  return (
    <Layout>
      <div className="wrapper">
        <div className="legal-returns-app">
          <h1 className="legal-returns-app__title">
            SAFEDOME APP TERMS - PLEASE READ CAREFULLY BEFORE USING THE APP{" "}
          </h1>
          <p>&nbsp;</p>
          <p>
            The Safedome App requires a smartphone, internet access and the iOS
            or android operating system. Whilst it may be possible to download
            and use the App with a tablet device, the service is designed to
            operate with smartphones and features of the service may not be
            available if the App is downloaded to and/or operated with any
            device other than a smartphone.
          </p>
          <p>
            These are the terms and conditions of your end-user licence
            agreement (“App Agreement”) for the Safedome mobile application
            software (“App”). In addition to your rights under these terms and
            conditions, you may also have rights granted to you by statute.
            These terms and conditions do not affect such statutory rights (e.g.
            where the App is defective).
          </p>
          <p>
            As this version of the software is purely for trial, the App is
            provided “as is” and no guarantees are made regarding the functions
            and features seen within it.
          </p>
          <p>
            We licence use of the App to you on the basis of this App Agreement
            and subject to any rules or policies (“Appstore Rules”) applied by
            any appstore provider or operator from whose site you downloaded the
            App (“Appstore”).
          </p>
          <p>
            The Terms and Conditions and the Appstore Rules are incorporated
            into this App Agreement by reference. In the event of any
            inconsistency between this App Agreement, the Terms and Conditions
            and the Appstore Rules, the order of precedence will be: the Terms
            and Conditions (highest precedence); App Agreement; and Appstore
            Rules (lowest precedence).
          </p>
          <p>&nbsp;</p>

          <p>
            <strong>How the Contract is Formed Between Us</strong>{" "}
          </p>
          <ul>
            <li>
              [By using the App], you agree to the terms of the App Agreement
              which will bind you. The terms of the App Agreement include, in
              particular, the Terms and Conditions and limitations on liability
              in Condition 7.
            </li>
            <li>
              If you do not agree to the terms of this App Agreement, we will
              not license the App to you.
            </li>
            <li>
              You may cancel your use of the App at any time without affecting
              your service membership.
            </li>
            <li>
              You should print a copy of this App Agreement for future
              reference.
            </li>
          </ul>

          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">[1] Acknowledgements</p>
          <p>
            [1.1] This App Agreement and the Terms and Conditions apply to the
            App and any of the services accessible through the App (“App
            Functions”), including any updates or supplements to the App or any
            App Functions, unless they come with separate terms, in which case
            we will notify you of which terms apply.
          </p>
          <p>
            [1.2] We may change these terms at any time by sending you details
            of the change or notifying you when you next start the App. The new
            terms may be displayed on-screen and you may be required to read and
            accept them to continue your use of the App.
          </p>
          <p>
            [1.3] From time to time updates to the App may be issued through the
            Appstore. You may not be able to use the App Functions until you
            have downloaded the latest version of the App and accepted any new
            terms. As this version of the App is a demonstration version only it
            is not expected that any updates will be issued via the Appstore.
          </p>
          <p>
            [1.4] You will be assumed to have obtained permission from the owner
            of any device that is controlled, but not owned, by you and
            described in Condition 2.2 (“Device”) and to download a copy of the
            App onto the Device and stream any data in connection with it. You
            and they may be charged by your and their service providers for
            internet access on the Device. You accept responsibility in
            accordance with the terms of this App Agreement for the use of the
            App or any App Functions on or in relation to any Device, whether or
            not it is owned by you.
          </p>
          <p>
            [1.5] A copy of our Data Protection Notice as applies to the App
            Functions is included in the Terms and Conditions.
          </p>
          <p>
            [1.6] By using the App or any App Functions, you acknowledge that:
          </p>
          <p>
            [1.6.1.1] we will collect and use technical information about the
            Device and related software, hardware and peripherals for App
            Functions that are internet-based or wireless to improve our
            products and to provide any App Functions;
          </p>
          <p>
            [1.6.1.2] internet transmissions are never completely private or
            secure; and
          </p>
          <p>
            [1.6.1.3] any message or information you send using the App or any
            App Functions may be read or intercepted by others, even if there is
            a special notice that a particular transmission is encrypted.
          </p>
          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">
            [2] Grant and Scope of Licence
          </p>
          <p>
            [2.1] In return for you agreeing to abide by the terms of this App
            Agreement, we grant you a non-transferable, non-exclusive licence to
            use the App on the Devices, subject to these terms, the Terms and
            Conditions and the Appstore Rules. We reserve all other rights.
          </p>
          <p>
            [2.2] You may download a copy of the App onto your Device and to
            view, use and display the App on the Device for your personal
            purposes only.
          </p>
          <p>
            [2.3] We do not guarantee that the App will be compatible with all
            or any hardware and software which you may use. We do not guarantee
            that the App will be available all the time or at any specific time.
            We reserve the right to withdraw or modify this App at any time.
          </p>
          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">
            [3] Licence Restrictions
          </p>
          <p>
            Except as expressly set out in this App Agreement or as permitted by
            any local law, you agree:
          </p>
          <p>
            [3.1] not to copy the App except where such copying is incidental to
            normal use of the App or necessary for the purpose of back-up or
            security;
          </p>
          <p>
            [3.2] not to rent, lease, sub-license, loan, translate, merge,
            adapt, vary or modify the App;
          </p>
          <p>
            [3.3] not to alter or modify the whole or any part of the App, or
            permit the App or any part of it to be combined with, or become
            incorporated in, any other programs;
          </p>
          <p>
            [3.4] not to disassemble, decompile, reverse-engineer or create
            derivative works based on the whole or any part of the App or
            attempt to do any such thing except to the extent that (by virtue of
            section 296A of the Copyright, Designs and Patents Act 1988) such
            actions cannot be prohibited because they are essential for the
            purpose of achieving inter-operability of the App with another
            software program, and provided that the information obtained by you
            during such activities is:
          </p>
          <p>
            [3.4.1.1] used only for the purpose of achieving inter-operability
            of the App with another software program;
          </p>
          <p>
            [3.4.1.2] not unnecessarily disclosed or communicated without our
            prior written consent to any third party; and
          </p>
          <p>
            [3.4.1.3] not used to create any software that is substantially
            similar to the App;
          </p>
          <p>[3.5] to keep all copies of the App secure;</p>
          <p>
            [3.6] to include our copyright notice on all entire and partial
            copies of the App on any medium;
          </p>
          <p>
            [3.7] not to provide or otherwise make available the App in whole or
            in part (including object and source code), in any form to any
            person without prior written consent from us; and
          </p>
          <p>
            [3.8] to comply with all technology control or export laws and
            regulations that apply to the technology used or supported by the
            App or any App Functions (“Technology”); together the “Licence
            Restrictions”.
          </p>

          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">
            [4] Acceptable Use Restrictions
          </p>
          <p>You must not:</p>
          <p>
            [4.1] use the App or any App Functions in any unlawful manner, for
            any unlawful purpose, or in any manner inconsistent with this App
            Agreement, or act fraudulently or maliciously (e.g. by hacking into
            or inserting malicious code, such as viruses or harmful data, into
            the App, any App Functions or any operating system);
          </p>
          <p>
            [4.2] infringe our intellectual property rights or those of any
            third party in relation to your use of the App (to the extent that
            such use is not licensed by this App Agreement);
          </p>
          <p>
            [4.3] use of the App or any App Functions to transmit any material
            that is defamatory, offensive or otherwise objectionable;
          </p>
          <p>
            [4.4] use the App or any App Functions in a way that could damage,
            disable, overburden, impair or compromise our systems or security or
            interfere with other users; and
          </p>
          <p>
            [4.5] collect or harvest any information or data from any App
            Functions or our systems or attempt to decipher any transmissions to
            or from the servers running any App Functions, together the
            “Acceptable Use Restrictions”.
          </p>
          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">
            [5] Intellectual Property Rights
          </p>
          <p>You acknowledge that:</p>
          <p>
            [5.1 ] the App, all the materials contained in it (including,
            without limitation, the design, layout, look, appearance, graphics
            and documents on the App, as well as other content such as articles,
            stories and other text) and the Technology are protected by
            intellectual property rights, including copyright, that either
            belong to us or are licensed to us to use;
          </p>
          <p>[5.2] rights in the App are licensed (not sold) to you;</p>
          <p>
            [5.3 ] you have no rights in, or to, the App or the Technology other
            than the right to use each of them in accordance with the terms of
            this App Agreement; and
          </p>
          <p>
            [5.4 ] you have no right to have access to the App in source-code
            form.
          </p>
          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">[6] Limited Warranty</p>
          <p>
            [6.1] We warrant that the App will, when properly used and on an
            operating system for which it was designed, perform substantially in
            accordance with the functions described on www.safedome.com and in
            the Terms and Conditions for a period of 30 days from the date on
            which the App is downloaded to the Device (“Warranty Period”).
          </p>
          <p>
            [6.2] The warranty does not apply if the defect or fault in the App
            or any App Functions results from you having amended the App or used
            the App in contravention of the terms of this App Agreement
            (including, without limitation, where you breach any of the Licence
            Restrictions and/or the Acceptable Use Restrictions).
          </p>
          <p>
            [6.3] This warranty is in addition to your legal rights in relation
            to software that is faulty or not as described.
          </p>
          <p>
            [6.4] If you require any assistance to use the App, please contact
            us.
          </p>
          <p>&nbsp;</p>

          <p className="legal-returns-app__para-title">
            [7] Limitation of Liability
          </p>
          <p>
            [7.1] You acknowledge that the App has not been developed to meet
            your individual requirements, and that it is therefore your
            responsibility to ensure that the facilities and functions of the
            App meet your requirements.
          </p>
          <p>[7.2] Unless Condition 7.3 applies:</p>
          <p>
            [7.2.1] we accept no liability for any damage, loss, cost or expense
            caused to you where such damage, loss, cost or expense is not
            reasonably foreseeable to you and us when you agreed to this App
            Agreement, including, without limitation, where the damage, loss,
            cost or expense:
          </p>
          <p>
            [7.2.1.1] results from our negligence or our breach of this App
            Agreement; or
          </p>
          <p>
            [7.2.1.2] arises from your use of, or your inability to use, any
            aspect of the App (or any part of it) for whatever reason (unless
            due to our breach of this App Agreement);
          </p>
          <p>
            [7.2.2] except as set out in Condition 6, we make no representations
            or warranties, express or implied, statutory or otherwise, as to any
            benefit or right that you obtain or expect to obtain as a result of
            your use of the App and/or App Functions;
          </p>
          <p>
            [7.2.3] we shall not be liable for any loss, damage, costs or
            expenses you suffer as a result of any failure or delay by us in
            providing the App and/or App Functions where such failure or delay
            arises out of any event outside of our reasonable control. Such
            events shall include any act, omission or accident, for example,
            acts of God; strikes and other industrial disputes; breakdown of
            computer systems or network access; fire or explosions, or power
            failure;
          </p>
          <p>
            [7.2.4] we are only liable to you for losses which you suffer as a
            result of a breach of this App Agreement by us. We are not
            responsible to you for any losses which you may incur which were not
            a foreseeable consequence of us breaching this App Agreement, for
            example if you and we could not have contemplated those losses
            before or when you access the App and/or App Functions; and
          </p>
          <p>
            [7.2.5] we only supply the App for domestic and private use. You
            agree not to use the App for any commercial, business or resale
            purposes, and we have no liability to you for any business losses
            that you may incur, including but not limited to loss of data, loss
            of profits, loss of business, business interruption, or loss of
            business opportunity.
          </p>
          <p>
            [7.3] Nothing in this App Agreement shall limit or exclude our
            liability for:
          </p>
          <p>[7.3.1] death or personal injury resulting from our negligence;</p>
          <p>[7.3.2] fraud or fraudulent misrepresentation; and</p>
          <p>
            [7.3.3] any matter which it would be illegal for us to exclude or to
            attempt to exclude our liability.
          </p>
          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">[8] Termination</p>
          <p>
            [8.1] We may terminate this App Agreement and your licence to access
            and use the App immediately by written notice to you if:
          </p>
          <p>
            [8.1.1] you commit a material breach of this App Agreement which you
            fail to remedy (if remediable) within 14 days after the service of
            written notice requiring you to do so;
          </p>
          <p>
            [8.1.2] you breach any of the Licence Restrictions or the Acceptable
            Use Restrictions; and
          </p>
          <p>[8.1.3] at any time, you:</p>
          <p>
            [8.1.3.1] give us false or misleading information in relation to
            your membership of the service; or
          </p>
          <p>[8.1.3.2] attempt to defraud us or act dishonestly towards us.</p>
          <p>
            [8.2] In addition, we reserve the right to terminate this App
            Agreement and discontinue your access to the App at any time for any
            reason by giving you at least seven days’ notice.
          </p>
          <p>[8.3] On termination for any reason:</p>
          <p>
            [8.3.1] all rights granted to you under this App Agreement shall
            cease;
          </p>
          <p>
            [8.3.2] you must immediately cease all activities authorised by this
            App Agreement, including your use of the App;
          </p>
          <p>
            [8.3.3] you must immediately delete or remove the App from your
            Device, and immediately destroy all copies of the App then in your
            possession, custody or control and certify to us that you have done
            so.
          </p>

          <p>&nbsp;</p>
          <p className="legal-returns-app__para-title">
            [9] Communication Between Us
          </p>
          <p>[9.1] You can contact us by email at hello@safedome.com.</p>
          <h3>&nbsp;</h3>
          <p className="legal-returns-app__para-title">
            [10] Other Important Terms
          </p>
          <p>
            [10.1] We may transfer our rights and obligations under this App
            Agreement to another organisation, but this will not affect your
            rights or our obligations under this App Agreement.
          </p>
          <p>
            [10.2] Your rights and obligations under this App Agreement are
            non-transferable. This means that only you are entitled to the
            rights given to you under this App Agreement and you cannot
            transfer, assign, charge, sub-contract or otherwise dispose of your
            rights or obligations under this App Agreement or give such rights
            away to someone else.
          </p>
          <p>
            [10.3] If we choose not to enforce a term or condition contained in
            this App Agreement, this will not prevent us from enforcing that
            term or condition later or relying on that term or condition in the
            future.
          </p>
          <p>
            [10.4] Each of the conditions of this App Agreement operates
            separately. If any court or competent authority decides that any of
            them are unlawful or unenforceable, the remaining conditions will
            remain in full force and effect.
          </p>
          <p>
            [10.5] This contract is between you and us. No other person shall
            have the right to enforce any of its terms.
          </p>
          <p>
            [10.6] English law will be used to decide any legal questions or
            disputes concerning the App, App Functions and this App Agreement,
            or about our dealings with you before you agreed to them. The courts
            of England will be able to deal with any questions or disputes
            concerning the App, App Functions and/or this App Agreement,
            although this does not affect your statutory rights.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default LegalApp;
